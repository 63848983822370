<template>
  <sgv-table
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, idx}">
      <tr>
        <td>
          {{idx+1}}.
          <span style="white-space: pre-line;">{{item.name}}</span><br>
        </td>

        <td>
          <span v-if="item.qty !== ''">{{item.qty | comma}}</span>
          <span v-else>-</span>
        </td>

        <td>
          <span v-if="item.price !== ''">{{item.price | comma}}</span>
          <span v-else>-</span>
        </td>

        <td>
          <span :class="{'text-danger': item.totalPrice < 0}">
            {{item.totalPrice | comma}}
          </span>
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import { LIST_ORDER, WATCH_DOC_UPDATED } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      skip () {
        return !(this.docId > 0)
      }
    },
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        },
      },
    },
  },
  computed: {
    headers () {
      return [
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา`, value: 'totalPrice'}
      ]
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    }
  },
  methods: {
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  }
}
</script>

<style lang="css">
</style>
