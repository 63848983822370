import gql from 'graphql-tag'


const orderResponse = `
  id type name parentId
  docId doc {id type code name}
  unitId unit {id name}
  inventoryId inventory {id type code name}
  qty price totalPrice
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`