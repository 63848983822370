import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType name isDefault parentId order
  options
  paperId paper {id code name}
  deliveryWorkerId deliveryWorker {id code name}
  vehicleId vehicle {id type code name}
  children {
    id type docType name isDefault
    parentId order regexExp
  }
`

export const LIST_DOC_CONFIG_MENU = (templateType) => gql`query LIST_DOC_CONFIG_MENU ($docType: String!) {
  menus: listDoc${templateType}DocConfigMenu (docType: $docType)
}`

export const LIST_DOC_CONFIG = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfig (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG = (templateType) => gql`mutation CREATE_DOC_CONFIG ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG_CHILDREN = (templateType) => gql`mutation CREATE_DOC_CONFIG_CHILDREN ($docType: String!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, input: $input) {
    id type docType name isDefault
    parentId order regexExp
  }
}`

export const DESTROY_DOC_CONFIG = (templateType) => gql`mutation DESTROY_DOC_CONFIG ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId) {id}
}`

export const MOVE_DOC_CONFIG = (templateType) => gql`mutation MOVE_DOC_CONFIG ($docType: String!, $docConfigId: Int!, $step: Int!) {
  item: moveDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId, step: $step) {id}
}`
