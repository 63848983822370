<template>
  <div class="">
    <sgv-input-date
      label="วันที่ส่งสินค้า"
      v-model="approvedDate"
      class="col-12 col-md-4"
      :validations="[
        {text: 'required!', value: $v.approvedDate.$dirty && $v.approvedDate.$error}
      ]">
    </sgv-input-date>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  CLOSE_DOC
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      approvedDate: this.$date.current().date,
    }
  },
  validations: {
    approvedDate: { required }
  },
  methods: {
    closeData () {
      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          approvedDate: this.approvedDate
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จบรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
