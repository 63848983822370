<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-number
          v-for="doc in docs"
          :key="doc.id"
          class="col-4 col-md-2"
          v-model="doc.qty">
          <label
            slot="label"
            class="pointer"
            :class="{'text-warning': doc.isMark}"
            @click="markLabel(doc.id)">
            {{doc.code}}
          </label>
        </sgv-input-number>
      </div>

      <div class="form-row">
        <sgv-input-date
          label="วันที่ส่งสินค้า"
          v-model="approvedDate"
          class="col-12 col-md-4"
          :validations="[
            {text: 'required!', value: $v.approvedDate.$dirty && $v.approvedDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  APPROVE_DOC,
  LIST_SALEORDER_AVAILABLE
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      docs: [],
      approvedDate: this.$date.format(new Date(), 0, 1).date,
      salesorders: []
    }
  },
  validations: {
    approvedDate: { required }
  },
  apollo: {
    salesorders: {
      query () {
        return LIST_SALEORDER_AVAILABLE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      result (res) {
        this.docs = res.data.salesorders.map(v => {
          return {
            id: v.id,
            code: v.code,
            qty: v.orders.length,
            isMark: false
          }
        })
      }
    }
  },
  methods: {
    markLabel (docId) {
      const found = this.docs.find(v => v.id === docId)

      if (found) {
        found.isMark = !found.isMark
      }
    },
    approveData () {
      this.$v.approvedDate.$touch()
      if (this.$v.approvedDate.$invalid) return

      const isMarked = this.docs.some(v => v.isMark)
      const docs = isMarked ? this.docs.filter(v => v.isMark) : this.docs

      this.$apollo.mutate({
        mutation: APPROVE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          approvedDate: this.approvedDate,
          docs: docs.map(v => ({
            docId: v.id,
            qty: v.qty
          }))
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จัดส่งสินค้า")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
